<template>
  <v-dialog
      :persistent="loading"
      :value="internalValue"
      @input="onInputDialog"
  >
    <v-card class="edit-dialog-content">
      <v-card-title class="text-h5">
        {{ dialogTitle }}
      </v-card-title>
      <v-expand-transition>
        <v-card-text
            v-if="!success"
            style="max-height: 55vh"
            class="overflow-y-auto"
        >
          <v-form
              ref="form"
              v-model="form.valid"
          >
            <eins-form-address v-model="form.data" />
          </v-form>
        </v-card-text>
      </v-expand-transition>
      <v-alert
          text
          tile
          type="success"
          transition="expand-transition"
          :value="success"
      >
        <div class="font-weight-bold">
          {{ successMessage.title }}
        </div>
        <div>
          {{ successMessage.text }}
        </div>
      </v-alert>
      <v-expand-transition>
        <v-card-actions
            v-if="!success"
            class="flex-column"
        >
          <v-btn
              class="white--text font-weight-bold"
              block
              color="primary"
              :disabled="!form.valid"
              :loading="loading"
              @click="onClickSubmit"
          >{{ submitLabel }}</v-btn>
          <v-btn
              block
              class="mx-0 mt-2"
              outlined
              :disabled="loading"
              @click="onClickCancel"
          ><i18n path="msc.actions.cancel" /></v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <eins-btn-timed
              block
              color="primary"
              outlined
              :disabled="loading"
              @click="onClickClose"
          >
            <i18n path="msc.actions.close" />
          </eins-btn-timed>
        </v-card-actions>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import EinsFormAddress from '@/components/form/EinsFormAddress'
import EinsBtnTimed from '@/components/btn/EinsBtnTimed'

export default {
  name: 'EinsEditLocationAddressDialog',
  components: {
    EinsBtnTimed,
    EinsFormAddress
  },
  props: {
    apiUrl: {
      type: String,
      default: null
    },
    afterUpdate: {
      type: Function,
      required: true
    },
    currentValue: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    },
    dialogTitle: {
      type: String,
      required: true
    },
    submitLabel: {
      type: String,
      required: true
    },
    successMessage: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    internalValue: false,
    loading: false,
    success: false,
    form: {
      data: {
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        country: ''
      },
      valid: false
    }
  }),
  computed: {
    cGroupId () {
      return this.$store.getters['groups/selected/group']?.id
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: 'onUpdateValue'
    }
  },
  methods: {
    onUpdateValue (value) {
      this.setValue(value)
      this.reset()
    },
    onInputDialog (value) {
      this.setValue(value)
      this.emitInput()
    },
    onClickSubmit () {
      this.submit()
    },
    onClickCancel () {
      this.closeDialog()
    },
    onClickClose () {
      this.closeDialog()
    },
    setValue (value) {
      this.internalValue = value
    },
    emitInput () {
      this.$emit('input', this.internalValue)
    },
    submit () {
      this.loading = true
      this.$http.$api.patch(this.apiUrl, {
        street: this.form.data.street,
        house_number: this.form.data.house_number,
        postal_code: this.form.data.postal_code,
        city: this.form.data.city,
        country: this.form.data.country
      })
        .then(() => {
          this.success = true
        })
        .then(() => this.afterUpdate())
        .finally(() => {
          this.loading = false
        })
    },
    closeDialog () {
      this.internalValue = false
      this.emitInput()
      this.reset()
    },
    reset () {
      this.success = false
      this.form.data = this.currentValue
        ? {
          street: this.currentValue.street,
          house_number: this.currentValue.house_number,
          postal_code: this.currentValue.postal_code,
          city: this.currentValue.city,
          country: this.currentValue.country ?? 'DE'
        }
        : {
          street: '',
          house_number: '',
          postal_code: '',
          city: '',
          country: 'DE'
        }
      this.resetValidation()
    },
    resetValidation () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    }
  }
}
</script>

<style scoped>

</style>
