<template>
  <v-row
      :style="cStyle"
      class="flex-column"
      no-gutters
  >
    <v-col class="flex-grow-0">
      <v-tabs
          v-model="tab"
          fixed-tabs
          height="32"
      >
        <v-tab><i18n path="input.location.position" /></v-tab>
        <v-tab><i18n path="input.location.address" /></v-tab>
      </v-tabs>
    </v-col>
    <v-col style="position: relative;">
      <v-tabs-items
          v-model="tab"
          class="fill-height"
      >
        <v-tab-item
            class="fill-height"
            style="position: relative;"
        >
          <eins-map-detail-position
              :position="cMapCenter"
              :hide-marker="!cHasValidGeolocation"
              :style="{ filter: cHasValidGeolocation ? null : 'blur(5px)' }"
          />
          <v-row
              v-if="!cHasValidGeolocation"
              style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5);"
              align="center"
              justify="center"
              no-gutters
          >
            <i18n
                path="input.location.noGeolocationSet"
                class="text-uppercase"
                tag="div"
            />
          </v-row>
        </v-tab-item>
        <v-tab-item class="fill-height">
          <v-row
              class="fill-height"
              align="center"
              justify="center"
          >
            <v-col class="flex-grow-0">
              <div class="overflow-hidden text-no-wrap pa-1">
                <template v-if="cAddress">
                  <div class="mb-1">
                    {{ cAddress.street }} {{ cAddress.house_number }}
                  </div>
                  <div class="mb-1">
                    {{ cAddress.postal_code }} {{ cAddress.city }}
                  </div>
                  <div>
                    {{ cAddress.countryName }}
                  </div>
                </template>
                <template v-else>
                  <div class="font-italic text-uppercase grey--text text--darken-2">
                    <i18n path="input.location.noAddressSet" />
                  </div>
                </template>
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-btn
          color="accent"
          fab
          small
          style="position: absolute; left: 0.5em; bottom: 0.5em; z-index: 400;"
          @click="onClickEdit"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </v-col>
    <v-col class="flex-grow-0">
      <v-expand-transition>
        <v-alert
            dense
            border="left"
            text
            tile
            type="info"
            class="text-caption"
        >
          <i18n path="input.location.calculationHint" />
        </v-alert>
      </v-expand-transition>
    </v-col>
    <eins-edit-location-address-dialog
        v-model="addressDialog"
        :after-update="afterUpdate"
        :api-url="apiUrl"
        :current-value="cAddress"
        :dialog-title="dialogTitleAddress"
        :success-message="successMessageAddress"
        :submit-label="submitLabelAddress"
    />
    <eins-edit-location-position-dialog
        v-model="positionDialog"
        :after-update="afterUpdate"
        :api-url="apiUrl"
        :current-value="cEntityPosition"
        :success-message="successMessagePosition"
    />
  </v-row>
</template>

<script>
import { Map } from '@/constants'
import EinsMapDetailPosition from '@/components/map/EinsMapDetailPosition'
import EinsEditLocationAddressDialog from '@/components/form/edit/EinsEditLocationAddressDialog'
import EinsEditLocationPositionDialog from '@/components/form/edit/EinsEditLocationPositionDialog'
import {
  isValidGeolocation
} from '@/utils/geo'

export default {
  name: 'EinsEditLocationBlock',
  components: {
    EinsEditLocationPositionDialog,
    EinsEditLocationAddressDialog,
    EinsMapDetailPosition
  },
  props: {
    aspectRatio: {
      type: String,
      default: null
    },
    apiUrl: {
      type: String,
      required: true
    },
    afterUpdate: {
      type: Function,
      required: true
    },
    height: {
      type: [String, Number],
      default: null
    },
    entity: {
      type: Object,
      required: true
    },
    dialogTitleAddress: {
      type: String,
      required: true
    },
    successMessageAddress: {
      type: Object,
      required: true
    },
    successMessagePosition: {
      type: Object,
      required: true
    },
    submitLabelAddress: {
      type: String,
      required: true
    }
  },
  data: () => ({
    tab: null,
    addressDialog: false,
    positionDialog: false
  }),
  computed: {
    cAddress () {
      if (
        this.entity &&
        this.entity.street &&
        this.entity.house_number &&
        this.entity.postal_code &&
        this.entity.city &&
        this.entity.country
      ) {
        return {
          street: this.entity.street,
          house_number: this.entity.house_number,
          postal_code: this.entity.postal_code,
          city: this.entity.city,
          country: this.entity.country,
          countryName: this.$t(`countries.names.${this.entity.country}`)
        }
      }

      return null
    },
    cEntityPosition () {
      if (this.entity) {
        return {
          lat: this.entity.latitude,
          lon: this.entity.longitude
        }
      }

      return null
    },
    cHasValidGeolocation () {
      return isValidGeolocation(this.cEntityPosition)
    },
    cUserPosition () {
      return {
        lat: this.$store.getters['players/current/latitude'],
        lon: this.$store.getters['players/current/longitude']
      }
    },
    cMapCenter () {
      if (isValidGeolocation(this.cEntityPosition)) {
        return this.cEntityPosition
      } else if (isValidGeolocation(this.cUserPosition)) {
        return this.cUserPosition
      } else {
        return {
          lat: Map.CENTER_DEFAULT_LAT,
          lon: Map.CENTER_DEFAULT_LNG
        }
      }
    },
    cStyle () {
      const result = {}

      if (this.height) {
        result.height = typeof this.height === 'number'
          ? `${this.height}px`
          : this.height
      } else {
        result.aspectRatio = this.aspectRatio
          ? this.aspectRatio
          : 1.25
      }

      return result
    }
  },
  methods: {
    onClickEdit () {
      if (this.tab === 1) {
        this.showAddressDialog()
      } else {
        this.showPositionDialog()
      }
    },
    showAddressDialog () {
      this.addressDialog = true
    },
    showPositionDialog () {
      this.positionDialog = true
    }
  }
}
</script>

<style scoped>

</style>
